/* eslint-disable import/no-cycle */
import { makeObservable, observable, action, runInAction, computed, toJS } from 'mobx';
import UploadPhotoVM from 'src/components/UploadPhoto/vm';
import IS from 'src/utils/is';

class KeyFinding {
  @observable title;
  @observable description;
  @observable photos = [];
  @observable videos = [];

  @observable uploadPhotoVM;

  constructor(params) {
    makeObservable(this);
    this.deserialize(params);

    runInAction(() => {
      this.uploadPhotoVM = new UploadPhotoVM({ maxCount: 1, items: toJS(this.photos), type: 'event' });
    });
  }

  @action updatePhotos = (data) => {
    this.photos = data;
  };

  @computed get photoUrl() {
    return this.photos?.length !== 0 && this.photos[0]?.url;
  }

  @computed get video() {
    return this.videos[0];
  }

  set video(value) {
    if (value) {
      this.videos = [value];
    } else {
      this.videos = [];
    }
  }

  @computed get isVideoValid() {
    return IS.youtube(this.video) || !this.video;
  }

  @computed get isComplete() {
    return !!this.title
      && !!this.description
      && (this.photos.length !== 0 || !!this.video);
  }

  // ///////////////////////

  @action deserialize = (data) => {
    const {
      title,
      description,
      photos,
      videos
    } = data ?? {};

    this.title = title;
    this.description = description;
    this.photos = photos ?? [];
    this.videos = videos ?? [];
  };

  serialize = () => {
    const data = {
      title: this.title,
      description: this.description,
      photos: this.photos,
      videos: this.videos
    };

    return data;
  };


  static fromRes(data) {
    return new KeyFinding(data);
  }
}

export default KeyFinding;
