/* eslint-disable import/no-cycle */
import Event from './Event';

export default class EventJoined {
  constructor(params) {
    const {
      acceptedAt,
      createdAt,
      demand,
      event,
      id
    } = params ?? {};

    this.acceptedAt = acceptedAt;
    this.createdAt = createdAt;
    this.demand = demand;
    this.event = new Event(event);
    this.id = id;
  }


  static fromRes(data) {
    return new EventJoined(data);
  }
}
